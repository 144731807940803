<template>
	<div>
  	<Breadcrumbs :items="crumbs" />

    <div class="fullscreen-bg videoWrapper" v-if="$vuetify.breakpoint.mdAndUp">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandmaster.mp4?alt=media&token=a980cf6d-2e32-45c0-8da8-547a79360be5">
        
      </video>
      <div class="overlay-clear">
        <v-container black--text>
          <div class="display-3">
            <span class="thin">Smart</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Wearables</span>
          </div>
        </v-container>
      </div>
    </div>

    <div class="pt-5 white relative" v-if="$vuetify.breakpoint.smAndDown">
      <div class="fullscreen-bg videoWrapper">
        <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
          <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandmastersmall.mp4?alt=media&token=33f4450f-926b-4517-b5fc-2010ce965c09">
          
        </video>
        
      </div>
      <div class="overlay-clear">
          <v-container black--text>
            <div class="display-3">
              <span class="thin">Smart</span>
            </div><br />
            <div class="display-4">
              <span class="thick">Wearables</span>
            </div>
          </v-container>
        </div>
    </div>

   
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Multi-touch, two-way real time data collection</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Personalized fashion</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Easily change messages</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Share with friends</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Increased fan engagement</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Create messages unique to your fan base</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Added value at conventions, stadiums and arenas</div>
            
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    
    <div class="white">
    <v-container grid-list-xs fluid>
      <v-layout wrap>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/band8.jpg?alt=media&token=da50022f-c53f-4f5d-bb3c-304cd1a9761e"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/band5.jpg?alt=media&token=b57f1214-e2cc-4f36-b498-570ac76da895"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/band2.jpg?alt=media&token=85b8fb7b-136c-4bf5-8b4c-3503c6a546f3"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/bandkings2.jpg?alt=media&token=f1a506a7-26b6-4445-a7dd-2cdd6cde45f4"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/bandkings1.jpg?alt=media&token=697005c1-e843-49ae-a52f-b450d56eebdb"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/band7.jpg?alt=media&token=87605504-aa5b-433a-87c1-32b75be3089f"
              :aspect-ratio="1.778"
            ></v-img>
          </v-card>
        </v-flex>



        
      </v-layout>
    </v-container>
    </div>


    <div class="fullscreen-bg2 videoWrapper" v-if="$vuetify.breakpoint.smAndDown">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandtwistsmall.mp4?alt=media&token=1c782fac-e874-42cc-9423-4d4ffa35246d">
        
      </video>
      <div class="overlay-clear" style="display:none;">
        <v-container>
          <div class="display-3">
            <span class="thin">Wearable</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Technology</span>
          </div>
        </v-container>
      </div>
    </div>




    <div class="fullscreen-bg2 videoWrapper" v-if="$vuetify.breakpoint.mdAndUp">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/0videosOfficial%2Fwristbandtwist.mp4?alt=media&token=ee3ef1f4-2fed-4df3-b219-f091a675fca6">
        
      </video>
      <div class="overlay-clear" style="display:none;">
        <v-container>
          <div class="display-3">
            <span class="thin">Wearable</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Technology</span>
          </div>
        </v-container>
      </div>
    </div>
    
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Wearables',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>